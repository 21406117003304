import axios from '@axios'

const state = () => ({
  customers: {
    data: [],
    meta: {
      pagination: {},
    },
  },
  selectedCustomerResourceId: JSON.parse(localStorage.getItem('selectedCustomerResourceId')) || null,
})

const getters = {
  customers(state) {
    return state.customers.data
  },
  pagination(state) {
    return state.customers.meta.pagination
  },
  selectedCustomerResourceId(state) {
    return state.selectedCustomerResourceId
  },
}

const mutations = {
  loading: (state, payload) => {
    state.loading = payload
  },
  SET_CUSTOMERS(state, payload) {
    state.customers = payload
  },
  SELECT_CUSTOMER_ID(state, payload) {
    localStorage.setItem('selectedCustomerResourceId', JSON.stringify(payload))
    state.selectedCustomerResourceId = payload
  },
  CHANGE_PAGE(state, page) {
    state.customers.meta.pagination.page = page
  },
}

const actions = {
  fetchCustomers(context, { byName = null, pagination = { page: 1, per_page: 10, all: false } }) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true)
      axios
        .get('/v1/customers', {
          params: {
            by_name: byName,
            meta: {
              pagination,
            },
          },
        })
        .then(response => {
          context.commit('SET_CUSTOMERS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          context.commit('loading', false)
        })
    })
  },

  createCustomer(context, customer) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true)
      axios
        .post('/v1/customers', {
          customer,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
        .finally(() => {
          context.commit('loading', false)
        })
    })
  },

  deactivateCustomer(context, customer) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/customers/${customer.id}`, {
          customer: { ...customer, active_status: !customer.active_status },
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  deleteCustomer(context, customerId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/v1/customers/${customerId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  updateOpenQuotation(context, { customerId, openQuotation }) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/v1/customers/${customerId}/update_open_quotation`, {
          openQuotation,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
